@import "./component//Reservation//css//reservation.css";
@import "./component//Invoice//css//Invoice.css";
@import "./component/Rate/Rate.css";

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.btn-right {
  float: right;
}

.map {
  height: 35em;
  width: 100%;
}

.reservationMap {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1281px) {
  .action .ant-table-header-column {
    width: 6em;
  }
}
@media only screen and (max-width: 1035px) {
  .ant-table-content,
  .ant-descriptions-view {
    overflow-x: auto;
  }
}
@media only screen and (max-width: 599px) {
  .ant-btn {
    height: 2em;
  }
}
@media only screen and (max-width: 599px) {
  .reservation_map {
    height: 20em;
  }
}

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  width: 250px;
}
.ol-popup:after {
  top: 100%;
  border: solid transparent;
  content: " ";
  position: absolute;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 40em;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

#loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ant-collapse-item{
  width: 51rem;
}
.notify-btn{
  right: 8rem;
  top: 4rem;
  position: absolute;
}
.logo{
  background-color: white !important;
}
.anticon svg{
  margin-top: -6px;
}
.download-button-spinner > .ant-spin-dot{
  left: 3rem;
}
.reload-button svg{
  color: black;
  font-size: 1.5rem;
}

.ant-dropdown {
  top: 0px !important;
  left: 0px !important;
}

.logo-mobile-view {
  position: fixed;
  background: white;
  z-index: 1;
  width: 100%;
}

.menu-mobile-view {
  margin-top: 4em;
  position: absolute;
}
