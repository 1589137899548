.area-spot-selection-container {
  border: 1px outset #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 40em;
}

.mobile-container {
  border: 2px solid #e8e8e8;
  border-radius: 20px;
  overflow: auto;
  padding: 8px 24px;
  height: 14em;
  position: absolute;
  z-index: 1;
  background-color: white;
  width: 80%;
  margin-left: 10%;
  margin-top: 30em;
  box-shadow: 0 0 5px 1px #807d77;
}

.mobile-reservation-map {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  height: 45em;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.area-spot-selection-container li:hover {
  background-color: rgba(217, 217, 217, 0.7);
  opacity: 0.9;
}

.mobile-container li:hover {
  background-color: rgba(217, 217, 217, 0.7);
  opacity: 0.9;
}
.available {
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 24;
  overflow: "hidden";
  box-shadow: 0 0 5px 1px #807d77;
}

.unavailable {
  border-radius: 10px;
  margin-bottom: 24;
  border: 0;
  overflow: "hidden";
  box-shadow: 0 0 5px 1px #807d77;
}

.unavailable li {
  cursor: not-allowed;
}

.unavailable.area-hover li:hover {
  background-color: rgba(255, 51, 0, 0.7);
  opacity: 0.9;
  cursor: not-allowed;
  border-radius: 10px;
}

.clickable-reservation-browser {
  cursor: pointer;
}

.clickable-reservation-browser:hover {
  color: grey;
}

.reservation-map-large-container {
  border: 1px solid grey;
}
