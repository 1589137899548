.main {
  height: 100vh;
  background: linear-gradient(90deg,
        rgb(252, 195, 16) 0%,
        rgba(225, 174, 5, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  height: 75vh;
  width: 75vw;
  display: flex;
  flex-direction: row;
  box-shadow: 3px 0px 100px 2px whitesmoke;
  border-radius: 50px;
}

.description {
  flex: 2;
  background: linear-gradient(180deg,
          rgb(252, 195, 16) 0%,
          rgba(225, 174, 5, 1) 100%);
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 10px;
}
.coverImg {
display: flex;
justify-content: center;
}
.coverText {
  display: flex;
  justify-content: center;
  padding-inline: 20% 20%;
}
.coverTextTitle {
  font-size: 4em;
  font-weight: bold;
  word-spacing: 10px;
  font-style: italic;
}
.coverTextTitle2 {
color: white;
}

.form {
  flex: 1;
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 10px;
}
.logoContainer {
  background-color: rgb(252, 195, 16);
  width: 150px;
  height: 150px;
  border-radius: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formWrapper {
  width: 100%;
  padding-inline: 10%;
  padding-top: 2em;
}