.Invoice-logo {
  height: 20vmin;
  float: right;
}

p.Header-text {
  font: outline;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 4em;
  font-weight: bold;
}
