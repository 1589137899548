.dashboardSalesWrapper {
  width: 49%;
  background: white;
  border-radius: 8;
  box-shadow: 0 8px 8px -4px #D3D3D3;
  .dashboardSalesTitle{
    padding: 10px;
  }
  .card-containter {
    padding: 10px;
  }
  .tabPaneWrapper {
    display: flex;
    flex-direction: row;
    .tabPaneContent {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    .total-amount {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    .no-data-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      font-weight: bold;
    }
  }
}
