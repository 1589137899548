.dashboard-rate-wrapper {
  width: 100%;
  background: white;
  border-radius: 8;
  box-shadow: 0 8px 8px -4px #D3D3D3;
  .rate-title {
    background: #51CBCE;
    padding: 10px;
  }
  .dashboard-rate {
    padding: 10px;
    .vehicle-type-title {
      font-weight: bold;
    }
    .rate-content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .rate-content {
        width: 32%;
        text-align: center;
        border-radius: 8;
        box-shadow: 0 8px 8px -4px #D3D3D3;
      }
    }
  }
}
