@import './parkingRateDisplay';
@import './dashboardSales';

.home {
  .dashboardWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
  }
  .ant-layout-content {
    background: none !important;
  }
}
